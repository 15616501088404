import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { observer } from "mobx-react-lite";
import { ZRolesViewAtts } from "src/common/roles/ZRolesViewAtts";
import { onError } from "src/common/onError";
import { DefaultOptionType } from "antd/es/select";
import { ZAttribute } from "src/types/ZAttribute";
import { AttrPersonSelectProps } from "src/common/attrEdit/components/ZAttrPersonSelect";
import { loadPersonsOptions } from "./loadPersonsOptions";

export type PropsPersonSelect = AttrPersonSelectProps & {
  value?: string[] | null;
  rolesViewAtts?: ZRolesViewAtts;
  onChange?(newValue?: string[] | null): void;
  attr: ZAttribute;
  disabled?: boolean;
  allowClear?: boolean;
  multiple?: boolean;
};

export const PersonSelect: React.FC<PropsPersonSelect> = observer(
  ({
    value,
    onChange,
    disabled = false,
    attr,
    multiple,
    allowClear = false,
    rolesViewAtts,
  }) => {
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<DefaultOptionType[]>([]);
    const init = async () => {
      setLoading(true);
      loadPersonsOptions(attr, rolesViewAtts)
        .then(setOptions)
        .finally(() => setLoading(false))
        .catch(onError);
    };
    useEffect(() => {
      init();
    }, []);

    return (
      <Select
        value={value}
        loading={loading}
        options={options}
        showSearch
        disabled={disabled}
        mode={multiple ? "multiple" : undefined}
        allowClear={allowClear}
        onChange={onChange}
        filterOption={(input, option) =>
          option?.searchText.indexOf(input.toLowerCase()) >= 0
        }
      />
    );
  },
);
