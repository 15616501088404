import * as React from "react";
import { Checkbox, Form, Input, InputNumber } from "antd";
import { observer } from "mobx-react-lite";
import { ruleRequired } from "src/common/validationRules/ruleRequired";
import { MCColumn, mkKey } from "../MCColumn";
import { EdMcRow } from "./EdMcTable";
import { ZMCRow } from "../../../ZMeasurementChart";
import { MChartEntityStore } from "../../MChartEntityStore";
import { MCSubtitle } from "./MCSubtitle";
import { MCTitle } from "./MCTitle";
import { PointSelectExt } from "../PointSelectExt";

const ptField = (row: ZMCRow, name: keyof EdMcRow) => [
  String(row.mcPoint.id),
  name,
];

const reqRules = [ruleRequired()];

export const buildColumns = (
  store: MChartEntityStore,
  hiddenColumns: Set<string>,
): MCColumn[] => [
  {
    key: "select",
    title: <AllCheck store={store} />,
    width: "34px",
    align: "center",
    rowSpan: 2,
    cell: (row) => <RowCheck row={row} store={store} />,
  },
  {
    key: "Point",
    title: "Point",
    width: "150px",
    rowSpan: 2,
    cell: (row) => <PointSelect row={row} store={store} />,
  },
  {
    key: "Description",
    title: "Description",
    width: "200px",
    rowSpan: 2,
    cell: (row) => (
      <Form.Item name={ptField(row, "description")} rules={reqRules}>
        <Input variant="borderless" allowClear />
      </Form.Item>
    ),
  },
  {
    key: "qc",
    title: "QC",
    width: "40px",
    align: "center",
    rowSpan: 2,
    cell: (row) => (
      <Form.Item name={ptField(row, "qc")} noStyle valuePropName="checked">
        <Checkbox />
      </Form.Item>
    ),
  },
  {
    key: "scale",
    title: "Scale",
    width: "60px",
    rowSpan: 2,
    cell: (row) => (
      <Form.Item
        name={ptField(row, "scale")}
        noStyle
        rules={[
          { required: true },
          {
            validator: (_, value) => {
              if (typeof value === "number" && value < 0)
                return Promise.reject(
                  Error("The value must not be less than zero"),
                );
              return Promise.resolve();
            },
          },
        ]}
      >
        <InputNumber variant="borderless" style={{ width: "100%" }} />
      </Form.Item>
    ),
  },
  {
    key: "tolMinus",
    title: "Tol -",
    width: "70px",
    rowSpan: 2,
    cell: (row) => (
      <Form.Item name={ptField(row, "tolMinus")} rules={reqRules}>
        <InputNumber variant="borderless" style={{ width: "100%" }} />
      </Form.Item>
    ),
  },
  {
    key: "tolPlus",
    title: "Tol +",
    width: "70px",
    rowSpan: 2,
    cell: (row) => (
      <Form.Item name={ptField(row, "tolPlus")} rules={reqRules}>
        <InputNumber variant="borderless" style={{ width: "100%" }} />
      </Form.Item>
    ),
  },
  ...store.sizeColumns.flatMap(({ name: szName }) => {
    const verSet = store.sizeVersions[szName];
    const srcList: string[] = verSet ? Array.from(verSet) : [];
    const verList: string[] = srcList.filter(
      (verName) => !hiddenColumns.has(mkKey(szName, verName)),
    );
    return verList.map(
      (verName, i) =>
        ({
          key: mkKey(szName, verName),
          szName,
          verName,
          noPadTitle: true,
          title:
            i === 0 ? (
              <MCTitle
                szName={szName}
                onAddVersion={
                  store.canAddVersion(szName)
                    ? () => store.addVersion(szName)
                    : undefined
                }
              />
            ) : null,
          colSpan: i === 0 ? verList.length : undefined,
          subTitle: (
            <MCSubtitle
              verName={verName}
              onDelete={
                store.canDeleteVersion(szName, verName)
                  ? () => store.deleteVersion(szName, verName)
                  : undefined
              }
            />
          ),
          width: "1fr",
          isBaseSize: szName === store.curBaseSizeName,
          cell: (row) => (
            <Form.Item
              name={[...ptField(row, "sizes"), `${szName}_${verName}`]}
              rules={reqRules}
            >
              <InputNumber variant="borderless" />
            </Form.Item>
          ),
        }) satisfies MCColumn,
    );
  }),
];

interface RowProps {
  row: ZMCRow;
  store: MChartEntityStore;
}

interface TableProps {
  store: MChartEntityStore;
}

const AllCheck: React.FC<TableProps> = observer(({ store }) => (
  <Checkbox
    checked={store.selectStatus === "all"}
    indeterminate={store.selectStatus === "partial"}
    onChange={() => store.toggleSelectAll()}
  />
));

const RowCheck: React.FC<RowProps> = observer(({ row, store }) => (
  <Checkbox
    checked={store.selected.has(row.mcPoint.id)}
    onChange={() => store.toggleSelect(row.mcPoint.id)}
  />
));

const PointSelect: React.FC<RowProps> = observer(({ row, store }) => {
  const form = Form.useFormInstance();
  return (
    <Form.Item name={ptField(row, "pointId")} rules={reqRules}>
      <PointSelectExt
        store={store}
        onChange={(id) => {
          const op = typeof id === "number" && store.pointDict[id];
          if (op) {
            const descName = ptField(row, "description");
            form.setFieldValue(descName, op.desc);
            form.validateFields([descName]);
          }
        }}
      />
    </Form.Item>
  );
});
