import { makeAutoObservable } from "mobx";
import { RemoteData } from "src/common/RemoteData";
import { EntityCardDataExt } from "src/pages/EntityCardPage/EntityCardStore";
import { ZBom2Settings } from "../Bom2Types";
import { loadBom2SettingsOpt } from "../apiBom2";

export const bom2ControlStore = makeAutoObservable({
  settings: { status: "none" } as RemoteData<ZBom2Settings>,
  setSettings(data: RemoteData<ZBom2Settings>) {
    this.settings = data;
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async init(objectServiceId: number, entityData: EntityCardDataExt) {
    try {
      this.setSettings({ status: "wait" });
      const result = await loadBom2SettingsOpt(objectServiceId);
      if (!result) throw Error("Не выполнена настройка сервиса");
      this.setSettings({ status: "ready", result });
    } catch (error) {
      this.setSettings({ status: "error", error });
    }
  },
});

export type Bom2ControlStore = typeof bom2ControlStore;
