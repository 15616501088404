import { DefaultOptionType } from "antd/es/select";
import React from "react";
import { apiLoadPersonList } from "src/common/apiPerson";
import { loadRolesDictCached } from "src/common/apiRoles";
import { PersonLabel } from "src/common/attrView/components/PersonCellInfo";
import { ZRolesViewAtts } from "src/common/roles/ZRolesViewAtts";
import { ZAttribute } from "src/types/ZAttribute";

export const loadPersonsOptions = async (
  attr: ZAttribute,
  rolesViewAtts?: ZRolesViewAtts,
  userIds?: string[],
): Promise<DefaultOptionType[]> => {
  const roleIds = attr.roleIds ?? [];
  const [persons, rolesDict] = await Promise.all([
    apiLoadPersonList({ roleIds, userIds: userIds ?? [] }),
    loadRolesDictCached(),
  ]);
  return persons.map((person) => {
    const searchTextFilter = person.attributeValues
      .map((attribute) => attribute.values?.join(" "))
      .filter(Boolean)
      .join(" ");
    return {
      label: (
        <PersonLabel
          key={person.id}
          person={person}
          rolesViewAtts={rolesViewAtts}
          rolesDict={rolesDict}
        />
      ),
      value: person.userId,
      searchText: searchTextFilter.toLowerCase(),
    };
  });
};
