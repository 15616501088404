import { z } from "zod";

export const zBom2Settings = z.object({
  objectServiceId: z.number(),
  bomObjectId: z.number(),
  positionObjectId: z.number(),
  colorObjectId: z.number(),
  colorModelObjectId: z.number(),
  supplierObjectId: z.number(),
  materialObjectId: z.number(),
  positionAttributes: z.object({
    nameId: z.number(),
    materialTypeId: z.number(),
    materialLocationId: z.number(),
    materialSubtypeId: z.number(),
    imageId: z.number(),
    locationId: z.number(),
    trimSizeId: z.number(),
    amountId: z.number(),
    commentId: z.number(),
    qualityDetailsId: z.number(),
    linkToBomPositionColorModelsId: z.number(),
  }),
  supplierAttributes: z.object({
    nameId: z.number(),
  }),
  colorAttributes: z.object({
    linkToModelId: z.number(),
    colorModelArticleId: z.number(),
    linkToColorInternalId: z.number(),
  }),
  bomPosColorModelAttributes: z.object({
    linkToBomPositionId: z.number(),
    linkToColorModelId: z.number(),
    linkToColorId: z.number(),
    colorManualId: z.number(),
    colorReferenceId: z.number(),
  }),
});

export type ZBom2Settings = z.infer<typeof zBom2Settings>;
