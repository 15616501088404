import * as React from "react";
import { observer } from "mobx-react-lite";
import { ModalVertFixed } from "src/components/ModalVertFixed";
import { Input } from "antd";
import { TableStore } from "src/components/tables/TableStore";
import { ScrollableTable } from "src/components/ScrollableTable";
import { AColumn } from "src/components/tables/AsyncTable";
import { debounce, DebounceCounter } from "src/common/debounce";
import { PointOpt } from "../MChartTable/PointDict";
import { PointSelectFilters } from "./PointSelectStore";

interface PropsPointSelectModal {
  title: string;
  tableStore: TableStore<PointOpt, PointSelectFilters> | null;
  success: (rows: PointOpt[]) => void;
  close: () => void;
}

const qeryCounter: DebounceCounter = {};

export const PointSelectModal: React.FC<PropsPointSelectModal> = observer(
  (props) => {
    const { title, tableStore, success, close } = props;
    const [query, setQuery] = React.useState("");
    React.useEffect(() => {
      setQuery("");
    }, [tableStore]);
    return (
      <ModalVertFixed
        title={title}
        height="80vh"
        open={!!tableStore}
        onCancel={close}
        width="700px"
        centered
        onOk={() => {
          success(tableStore?.selected ?? []);
          close();
        }}
      >
        {tableStore && (
          <ScrollableTable
            store={tableStore}
            columns={columns}
            onRowClick={(row) => {
              tableStore.safeSelect([row]);
            }}
            tools={
              <Input.Search
                style={{ flex: 1 }}
                placeholder="Search by name or description"
                value={query}
                onChange={(e) => {
                  const { value } = e.currentTarget;
                  setQuery(value);
                  debounce(qeryCounter, 600, () => {
                    tableStore.setFilters({ query: value });
                  });
                }}
              />
            }
          />
        )}
      </ModalVertFixed>
    );
  },
);

const columns: AColumn<PointOpt>[] = [
  { key: "label", dataIndex: "label", title: "Point name", sorter: true },
  { key: "desc", dataIndex: "desc", title: "Description", sorter: true },
];
