import * as React from "react";
import { observer } from "mobx-react-lite";
import { FormInstance, Modal, notification } from "antd";
import { LoaderBox } from "src/components/LoaderBox";
import { EntityFiltersPageStore } from "src/pages/EntityFiltersPage/EntityFiltersPageStore";
import { EntityFiltersTable } from "src/pages/EntityFiltersPage/EntityFiltersTable";
import { t } from "i18next";
import { onError } from "src/common/onError";
import styles from "./MChartCopyTo.module.less";
import { MChartEntityStore } from "../../MChartEntityStore";

interface PropsMChartCopyTo {
  form: FormInstance;
  store: MChartEntityStore;
  open: boolean;
  close: () => void;
}

export const MChartCopyTo: React.FC<PropsMChartCopyTo> = observer((props) => {
  const { store, open, close, form } = props;
  const optModelStore =
    store.table.status === "ready" ? store.table.result : null;

  const init = async () => {
    store.setTable({ status: "wait" });
    try {
      await form.validateFields();
    } catch (e) {
      throw Error("The errors need to be corrected");
    }
    if (!(await store.save(form.getFieldsValue()))) {
      throw Error("Failed to save data");
    }
    await store.initTable();
  };
  React.useEffect(() => {
    if (open) {
      init().catch((e) => {
        onError(e);
        close();
      });
    }
  }, [open]);
  if (!open) return null;
  return (
    <Modal
      title="Copy to another model"
      open={open}
      onCancel={close}
      width="80vw"
      centered
      okText={t("Copy")}
      okButtonProps={{
        disabled: !optModelStore?.tableStore?.selected.length,
        loading: store.buzy === "copy",
      }}
      onOk={() =>
        store.copyTo(form).then((success) => {
          if (success) {
            close();
            notification.success({
              message: "The measurement chart has been copied successfully",
            });
          }
        })
      }
    >
      <LoaderBox
        remoteData={store.table}
        drawReady={(tableStore) => <Inner tableStore={tableStore} />}
      />
    </Modal>
  );
});

interface PropsInner {
  tableStore: EntityFiltersPageStore;
}
const Inner: React.FC<PropsInner> = observer(({ tableStore }) => (
  <div className={styles.modalContent}>
    <EntityFiltersTable store={tableStore} />
  </div>
));
