import { rest } from "src/common/rest";
import { apiBomUrl } from "src/common/apiUrl";
import { ZBom2Settings, zBom2Settings } from "./Bom2Types";

export const loadBom2SettingsOpt = async (
  objectServiceId: number,
): Promise<ZBom2Settings> => {
  const resp = await rest.get(apiBomUrl(`/bom/object/${objectServiceId}`));
  try {
    return zBom2Settings.parse(resp.data);
  } catch (e) {
    // Когда создаётся экземпляр сервиса, то настройки пустые.
    // Но при первом сохранении они должны стать корректными.
    return resp.data as ZBom2Settings;
  }
};

export const saveBom2Settings = async (data: ZBom2Settings) => {
  await rest.post(apiBomUrl("/bom/object"), data);
};
