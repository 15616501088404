import * as React from "react";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import { theme, Button, Checkbox, Dropdown, Form, Modal } from "antd";
import {
  DeleteOutlined,
  DeleteRowOutlined,
  ExclamationCircleOutlined,
  ExportOutlined,
  FilterOutlined,
  FunnelPlotOutlined,
  PlusCircleOutlined,
  ProjectOutlined,
} from "@ant-design/icons";
import { TableStore } from "src/components/tables/TableStore";
import { confirmDelete } from "src/components/ModalDelete";
import { onError } from "src/common/onError";
import { MChartEntityStore } from "../MChartEntityStore";
import { MCInfoItem } from "./MCInfoItem";
import { MChartTable } from "./MChartTable/MChartTable";
import { MChartCopyTo } from "./MChartCopyTo";
import { PointOpt } from "./MChartTable/PointDict";
import {
  createPointSelectStore,
  PointSelectFilters,
} from "./PointSelectExt/PointSelectStore";
import { PointSelectModal } from "./PointSelectExt/PointSelectModal";
import styles from "./EditMCEntity.module.less";
import { isNonEmptyVersions } from "./isNonEmptyVersions";

const { useToken } = theme;

interface PropsEditMCEntity {
  store: MChartEntityStore;
}

export const EditMCEntity: React.FC<PropsEditMCEntity> = observer(
  ({ store }) => {
    const { buzy, data, selected, hiddenColumns } = store;
    const [form] = Form.useForm();
    const [openCopy, setOpenCopy] = React.useState(false);
    if (data.status !== "ready") return null;
    const { mc } = data.result;
    const disabled = !!buzy;
    const onReset = () => {
      Modal.confirm({
        title: t("Attention!"),
        content: "All data will be deleted",
        icon: <ExclamationCircleOutlined />,
        okButtonProps: { danger: true },
        onOk() {
          store.reset();
        },
      });
    };
    const onDeleteRow = () =>
      confirmDelete(t("Do you want to delete the selected records?"), () =>
        store.deleteSelected(),
      );
    const { token } = useToken();

    const contentStyle: React.CSSProperties = {
      backgroundColor: token.colorBgElevated,
      borderRadius: token.borderRadiusLG,
      boxShadow: token.boxShadowSecondary,
    };

    const [tableStore, setTableStore] = React.useState<TableStore<
      PointOpt,
      PointSelectFilters
    > | null>(null);
    const startAddPoints = () => {
      setTableStore(createPointSelectStore(store.pointOptions, "checkbox"));
    };
    const closeAddPoints = () => setTableStore(null);

    const onScale = () => {
      const haveChanges = isNonEmptyVersions(
        form.getFieldsValue(),
        store.selected,
      );
      const exec = () => {
        store.scale(form).catch(onError);
      };
      if (haveChanges) {
        Modal.confirm({
          title: "Overwrite measurement chart changes?",
          content:
            "Automatic scaling will change the dimension values ​​relative to the base size.",
          type: "error",
          okButtonProps: { danger: true },
          okText: "Overwrite",
          onOk: exec,
        });
      } else {
        exec();
      }
    };

    return (
      <>
        <Form
          form={form}
          className={styles.commonBox}
          onFinish={(values) => {
            store.save(values);
          }}
        >
          <div className={styles.infoRow}>
            <MCInfoItem value={store.curSizeLineName} label="Size Scale" />
            <MCInfoItem value={store.curBaseSizeName} label="Base Size" />
            <MCInfoItem
              value={mc.measurementChartEntityDto?.comment}
              label="Comment"
            />
          </div>
          <div className={styles.toolsRow}>
            <div className={styles.selectInfo}>
              {selected.size > 0 &&
                t("Selected lines count", { count: selected.size })}
            </div>
            <Button
              icon={<ProjectOutlined />}
              disabled={!store.canScale || disabled}
              loading={store.buzy === "scale"}
              onClick={onScale}
            >
              Scale
            </Button>
            <Dropdown
              trigger={["click"]}
              dropdownRender={() => (
                <div style={contentStyle} className={styles.dropDownBox}>
                  {store.sizeVersionsList.map(({ key, szName, verName }) => (
                    <div key={key}>
                      <Checkbox
                        checked={!hiddenColumns.has(key)}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          store.toggleColumnVisibility(key);
                        }}
                      >
                        {szName} - {verName}
                      </Checkbox>
                    </div>
                  ))}
                </div>
              )}
            >
              <Button
                icon={
                  hiddenColumns.size ? (
                    <FunnelPlotOutlined />
                  ) : (
                    <FilterOutlined />
                  )
                }
                title="Columns visibility"
              />
            </Dropdown>
            <Button
              icon={<ExportOutlined />}
              title="Copy to another model"
              disabled={disabled}
              onClick={() => setOpenCopy(true)}
            />
            <Button
              icon={<DeleteRowOutlined />}
              disabled={disabled || selected.size === 0}
              onClick={onDeleteRow}
            >
              Delete selected
            </Button>
            <Button
              icon={<PlusCircleOutlined />}
              disabled={disabled}
              onClick={() => store.addRow(form)}
            >
              {t("Add point")}
            </Button>
            <Button disabled={disabled} onClick={startAddPoints}>
              Add points...
            </Button>
            <Button
              icon={<DeleteOutlined />}
              danger
              onClick={onReset}
              loading={buzy === "reset"}
              disabled={disabled}
            >
              Reset
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              disabled={disabled}
              loading={buzy === "save"}
            >
              {t("Save")}
            </Button>
          </div>
          <MChartTable store={store} />
        </Form>
        <MChartCopyTo
          store={store}
          open={openCopy}
          close={() => setOpenCopy(false)}
          form={form}
        />
        <PointSelectModal
          title="Select points to create"
          tableStore={tableStore}
          close={closeAddPoints}
          success={(rows) => store.addRows(form, rows)}
        />
      </>
    );
  },
);
