import { Form, Input, InputNumber } from "antd";
import React, { useEffect, useMemo } from "react";
import {
  getArticleAttrCells,
  hasAttrArticleCounter,
  validateArticle,
} from "src/common/attrEdit/components";
import { loadObjectAttrinbutesAll } from "src/pages/ManagementPage/objectsApi";
import {
  ZArticleNumberInfo,
  ZArticleNumberInfoAttr,
} from "src/types/ZAttribute";
import { SelectFromReference } from "src/components/SelectFromReference";
import styles from "./ArticleTemplateInput.module.less";

const atts2OptionsLoaderStd = (objectId: string) =>
  loadObjectAttrinbutesAll(objectId).then((data) =>
    data.map((v) => ({ label: v.name, value: v.id })),
  );

const makeFormName = (base: string, partName: keyof ZArticleNumberInfo) => [
  base,
  partName,
];
const maskLinePartName = (base: string) => makeFormName(base, "maskLine");
const gbName = (base: string, name: keyof ZArticleNumberInfo["groupBy"]) => [
  ...makeFormName(base, "groupBy"),
  name,
];
const attsName = (
  base: string,
  i: number,
  name: keyof ZArticleNumberInfoAttr,
) => [...makeFormName(base, "attributes"), i, name];

type PropsArticleTemplateInput = {
  name: string;
  objectId: number;
};

export const ArticleTemplateInput: React.FC<PropsArticleTemplateInput> = ({
  name,
  objectId,
}) => {
  const attsOptions = useMemo(
    () => () => atts2OptionsLoaderStd(String(objectId)),
    [objectId],
  );
  const maskLineName = maskLinePartName(name);
  const form = Form.useFormInstance();
  const safeVal = String(Form.useWatch(maskLineName) || "");
  const hasCounter = hasAttrArticleCounter(safeVal);
  const cells = getArticleAttrCells(safeVal);
  const uniqCells = Array.from(
    new Map(cells.map((c) => [c.toLocaleUpperCase(), c])).values(),
  );
  useEffect(() => {
    uniqCells.forEach((v, i) =>
      form.setFieldValue(attsName(name, i, "linePart"), v),
    );
  }, [uniqCells]);

  return (
    <div className={styles.box}>
      <Form.Item
        name={maskLineName}
        label="Шаблон артикула"
        rules={[{ required: true }, validateArticle()]}
      >
        <Input />
      </Form.Item>

      {uniqCells.map((c, i) => (
        <div key={c} className={styles.row}>
          <Form.Item label="Составляющая" name={attsName(name, i, "linePart")}>
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="Атрибут"
            name={attsName(name, i, "attributeId")}
            rules={[{ required: true }]}
          >
            <SelectFromReference loader={attsOptions} />
          </Form.Item>
          <Form.Item
            label="Количество символов"
            name={attsName(name, i, "quantitySymbols")}
            rules={[{ required: true }]}
          >
            <InputNumber style={{ width: "100%" }} min={0} />
          </Form.Item>
          <Form.Item
            label="Cтрока замены"
            name={attsName(name, i, "replaceLine")}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </div>
      ))}

      {hasCounter && (
        <div className={styles.row}>
          <Form.Item
            label="Группировать счетчик"
            name={gbName(name, "attrIds")}
            rules={[{ required: true }]}
          >
            <SelectFromReference
              mode="multiple"
              maxTagCount="responsive"
              loader={attsOptions}
            />
          </Form.Item>
          <Form.Item
            label="Количество символов"
            name={gbName(name, "quantitySymbols")}
            rules={[{ required: true }]}
          >
            <InputNumber style={{ width: "100%" }} min={0} />
          </Form.Item>
          <Form.Item
            label="Начало отсчета"
            name={gbName(name, "counterStart")}
            rules={[{ required: true }]}
          >
            <InputNumber style={{ width: "100%" }} min={0} />
          </Form.Item>
        </div>
      )}
    </div>
  );
};
