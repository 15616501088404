import * as React from "react";
import { Form, Select, Spin } from "antd";
import { observer } from "mobx-react-lite";
import { SelectDictionary } from "src/pages/ManagementPage/Obj2Tab/forms/AttrForm2/SelectDictionary";
import { ZMesChartSettings } from "../ZMesChartSettings";
import { mChartSettings, MCObjType } from "./MChartSettingsStore";
import styles from "./MChartSettings.module.less";

const fkey = (name: keyof ZMesChartSettings) => name;

const fieldNames = { value: "id", label: "name" };

export const MChartSettings: React.FC = observer(() => {
  const { loading } = mChartSettings;
  React.useEffect(() => {
    mChartSettings.init();
  }, []);

  return (
    <Spin spinning={loading}>
      <div className={styles.box}>
        <DrawSection
          objKey="sizeLinesObjectId"
          objType="SizeLines"
          objLabel="Size Lines"
          attrs={[
            { attrKey: "sizeLinesNameAttrId", attrLabel: "Name" },
            {
              attrKey: "sizeLinesSizeLineSizeAttrId",
              attrLabel: "Size line sizes",
            },
          ]}
        />
        <DrawSection
          objKey="sizeLineSizeObjectId"
          objLabel="Size line size"
          objType="SizeLineSize"
          attrs={[
            { attrKey: "sizeLineAttrId", attrLabel: "Size line" },
            { attrKey: "sizeLineSizeNameAttrId", attrLabel: "Size Name" },
            {
              attrKey: "sizeLineSizePreviousSizeAttrId",
              attrLabel: "Previous size",
            },
          ]}
        />
        <DrawSection
          objKey="mcObjectId"
          objLabel="Measurement Chart"
          objType="MChart"
          attrs={[
            { attrKey: "mcNameAttrId", attrLabel: "Name" },
            { attrKey: "mcModelAttrId", attrLabel: "Model" },
            { attrKey: "mcSizeScaleAttrId", attrLabel: "Size scale" },
            { attrKey: "mcBaseSizeAttrId", attrLabel: "Base size" },
            { attrKey: "mcCommentAttrId", attrLabel: "Comment" },
            { attrKey: "mcMcPointAttrId", attrLabel: "MC points" },
            { attrKey: "mcImageAttrId", attrLabel: "Image" },
          ]}
        />
        <DrawSection
          objKey="msPointsObjectId"
          objLabel="MC points"
          objType="MCPoint"
          attrs={[
            { attrKey: "msPointsMcAttrId", attrLabel: "Measurement Chart" },
            { attrKey: "msPointsPointAttrId", attrLabel: "Point" },
            {
              attrKey: "msPointsDescriptionAttrId",
              attrLabel: "Description",
            },
            { attrKey: "msPointsQcAttrId", attrLabel: "QC" },
            { attrKey: "msPointsTolMinusAttrId", attrLabel: "Tol -" },
            { attrKey: "msPointsTolPlusAttrId", attrLabel: "Tol +" },
            { attrKey: "msPointsPointValueAttrId", attrLabel: "Point Value" },
            { attrKey: "msPointsScaleAttrId", attrLabel: "Scale" },
          ]}
        />
        <DrawSection
          objKey="pointObjectId"
          objLabel="Point"
          objType="Pt"
          attrs={[
            { attrKey: "pointPointNameAttrId", attrLabel: "Name" },
            { attrKey: "pointDescriptionAttrId", attrLabel: "Description" },
          ]}
        />
        <DrawSection
          objKey="sizeNameObjectId"
          objLabel="Size names"
          objType="SizeName"
          attrs={[
            { attrKey: "sizeNameNameAttrId", attrLabel: "Name" },
            { attrKey: "sizeNameSynonymsAttrId", attrLabel: "Synonyms" },
          ]}
        />
        <DrawSection
          objKey="pointValueObjectId"
          objLabel="Point value"
          objType="PointValue"
          attrs={[
            {
              attrKey: "pointValueMcPointsAttrId",
              attrLabel: "Point value Mc points",
            },
            { attrKey: "pointValueSizeAttrId", attrLabel: "Point Value Size" },
            {
              attrKey: "pointValueVersionAttrId",
              attrLabel: "Point Value Version",
            },
            {
              attrKey: "pointValueValueAttrId",
              attrLabel: "Point Value Value",
            },
          ]}
        />
        <hr />
        <Form.Item
          name={fkey("versionDictionaryNameAttrId")}
          label="Справочник версий"
          rules={[{ required: true }]}
        >
          <SelectDictionary store={mChartSettings.selectDict} />
        </Form.Item>
        <hr />
        <Form.Item
          name={fkey("templateObjectId")}
          label="Template object"
          rules={[{ required: true }]}
        >
          <Select
            options={mChartSettings.objects}
            fieldNames={fieldNames}
            showSearch
            optionFilterProp="name"
            allowClear
          />
        </Form.Item>
      </div>
    </Spin>
  );
});

interface AttrDef {
  attrKey: keyof ZMesChartSettings;
  attrLabel: string;
}
interface PropsSection {
  objKey: keyof ZMesChartSettings;
  objType: MCObjType;
  objLabel: string;
  attrs: AttrDef[];
}
const DrawSection: React.FC<PropsSection> = observer((props) => {
  const { objKey, objType, objLabel, attrs } = props;
  const { objects, attrsMap, loadingAttrs } = mChartSettings;
  const form = Form.useFormInstance();
  const idObj = Form.useWatch(fkey(objKey));
  React.useEffect(() => {
    mChartSettings.loadAttrs(objType, idObj);
  }, [idObj]);
  return (
    <>
      <Form.Item
        className={styles.section}
        name={fkey(objKey)}
        label={objLabel}
        rules={[{ required: true }]}
      >
        <Select
          options={objects}
          fieldNames={fieldNames}
          showSearch
          optionFilterProp="name"
          allowClear
          onChange={() => {
            form.resetFields(attrs.map(({ attrKey }) => attrKey));
          }}
        />
      </Form.Item>
      <div className={styles.threeCols}>
        {attrs.map(({ attrKey, attrLabel }) => (
          <Form.Item
            key={attrKey}
            name={fkey(attrKey)}
            label={attrLabel}
            rules={[{ required: true }]}
          >
            <Select
              options={attrsMap[objType] ?? []}
              fieldNames={fieldNames}
              loading={loadingAttrs.has(objType)}
            />
          </Form.Item>
        ))}
      </div>
    </>
  );
});
