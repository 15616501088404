import { EdMcTable } from "./MChartTable/EdMcTable";

export const isNonEmptyVersions = (
  values: EdMcTable,
  selected: Set<number>,
): boolean =>
  !!Object.entries(values).find(([strId, row]) => {
    if (!selected.has(+strId)) return false;
    return nonZeroRow(row.sizes);
  });

const nonZeroRow = (sizes: Record<string, number | null>): boolean =>
  !!Object.values(sizes).find((value) => !!value);
