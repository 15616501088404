import {
  ZMCRow,
  ZMCSize,
  ZMCSizeColumn,
  ZMCTable,
} from "../../../ZMeasurementChart";
import { NumOption, PointDict } from "./PointDict";

export type EdMcRow = {
  pointId: number | null;
  description: string | null;
  qc: boolean;
  scale: number | null;
  tolMinus: number | null;
  tolPlus: number | null;
  sizes: Record<string, number | null>;
};
export type EdMcTable = Record<string, EdMcRow>;

const makeEdSizes = (srcSizes: ZMCSize[]): EdMcRow["sizes"] => {
  const dstSizes: Record<string, number | null> = {};
  srcSizes.forEach((srcSize) => {
    srcSize.pointValues.forEach(({ version, value }) => {
      dstSizes[`${srcSize.name}_${version.name}`] = value;
    });
  });
  return dstSizes;
};

const mcRow2ed = ({ mcPoint }: ZMCRow): EdMcRow => ({
  pointId: mcPoint.point.id,
  description: mcPoint.description,
  qc: mcPoint.qc,
  scale: mcPoint.scale,
  tolMinus: mcPoint.tolMinus,
  tolPlus: mcPoint.tolPlus,
  sizes: makeEdSizes(mcPoint.sizes),
});

export const mcTable2ed = (rows: ZMCRow[]): EdMcTable => {
  const res: EdMcTable = {};
  rows.forEach((row) => {
    res[String(row.mcPoint.id)] = mcRow2ed(row);
  });
  return res;
};

export const createMcRow = (
  sizeVersions: Record<string, Set<string>>,
  sizeColumns: ZMCSizeColumn[],
  verOptions: NumOption[],
): ZMCRow => ({
  mcPoint: {
    // eslint-disable-next-line no-plusplus
    id: rowIndex--,
    point: {
      id: undefined as unknown as number,
      name: null,
    },
    description: null,
    tolPlus: null,
    tolMinus: null,
    scale: 0,
    needScale: null,
    sizes: Object.entries(sizeVersions).map(([szName, verSet]) => ({
      id: sizeColumns.find(({ name }) => name === szName)
        ?.id as unknown as number,
      name: szName,
      pointValues: Array.from(verSet).map((verName) => ({
        id: undefined as unknown as number,
        version: {
          id: verOptions.find(({ label }) => label === verName)
            ?.value as unknown as number,
          name: verName,
        },
        value: null,
      })),
    })),
    qc: false,
  },
});

let rowIndex = -1;

type CtxEd2Mc = {
  pointDict: PointDict;
  // verDict: Record<number, string>;
};

export const ed2mcTable = (
  ed: EdMcTable,
  src: ZMCTable,
  ctx: CtxEd2Mc,
): ZMCTable => ({
  rows: Object.entries(ed).map(([rowId, edRow]) =>
    ed2mcRow(rowId, edRow, src, ctx),
  ),
});

const newId = undefined as unknown as number;

const ed2mcRow = (
  rowId: string,
  edRow: EdMcRow,
  srcTable: ZMCTable,
  { pointDict }: CtxEd2Mc,
): ZMCRow => {
  const numId = +rowId;
  const srcRow = srcTable.rows.find(({ mcPoint }) => mcPoint.id === numId);
  const pointId = edRow.pointId as number;
  return {
    mcPoint: {
      id: numId < 0 ? newId : numId,
      point: {
        id: pointId, // даже если undefined, то всё равно
        name: pointDict[pointId]?.label ?? null,
      },
      description: edRow.description,
      tolMinus: edRow.tolMinus,
      tolPlus: edRow.tolPlus,
      qc: edRow.qc,
      sizes: ed2mcSizes(edRow.sizes, srcRow?.mcPoint.sizes),
      scale: edRow.scale,
      needScale: null,
    },
  };
};

const ed2mcSizes = (
  edSizes: Record<string, number | null>,
  srcSizes: ZMCSize[] | undefined,
): ZMCSize[] => {
  const dstSizeDict: Record<string, ZMCSize> = {};
  Object.entries(edSizes).forEach(([key, value]) => {
    const pair = key.split("_");
    if (pair.length !== 2) throw Error(`Invalid size key: ${key}`);
    const [sizeName, verName] = pair as [string, string];
    const srcSize = srcSizes?.find(({ name }) => name === sizeName);
    const dstSizeItem =
      dstSizeDict[sizeName] ??
      ({
        id: srcSize?.id ?? newId,
        name: sizeName,
        pointValues: [],
      } satisfies ZMCSize);
    dstSizeDict[sizeName] = dstSizeItem;
    const srcVer = srcSize?.pointValues.find(
      ({ version }) => version.name === verName,
    );
    dstSizeItem.pointValues.push(
      srcVer
        ? { ...srcVer, value }
        : {
            id: newId,
            version: { id: newId, name: verName },
            value,
          },
    );
  });
  return Object.values(dstSizeDict);
};
