import * as React from "react";
import { DownOutlined } from "@ant-design/icons";
import { TableStore } from "src/components/tables/TableStore";
import { PointOpt } from "../MChartTable/PointDict";
import { MChartEntityStore } from "../../MChartEntityStore";
import { PointSelectModal } from "./PointSelectModal";
import { createPointSelectStore, PointSelectFilters } from "./PointSelectStore";
import styles from "./PointSelectExt.module.less";

interface PropsPointSelectExt {
  store: MChartEntityStore;
  value?: number;
  onChange: (newValue?: number) => void;
}

export const PointSelectExt: React.FC<PropsPointSelectExt> = (props) => {
  const { value, onChange, store } = props;
  const [tableStore, setTableStore] = React.useState<TableStore<
    PointOpt,
    PointSelectFilters
  > | null>(null);
  const close = () => setTableStore(null);
  const open = () => {
    const newTableStore = createPointSelectStore(store.pointOptions, "radio");
    if (value) {
      const row = store.pointOptions.find(
        ({ value: optValue }) => value === optValue,
      );
      if (row) newTableStore.selected.push(row);
    }
    setTableStore(newTableStore);
  };
  const opt = store.pointOptions.find(
    ({ value: optValue }) => optValue === value,
  );
  return (
    <>
      <button className={styles.controlBox} onClick={open} type="button">
        <div className={styles.controlValue}>{opt?.label ?? value}</div>
        <DownOutlined />
      </button>
      <PointSelectModal
        title="Selecting a point"
        tableStore={tableStore}
        close={close}
        success={(rows) => onChange(rows[0]?.value)}
      />
    </>
  );
};

PointSelectExt.defaultProps = {
  value: undefined,
};
