import { makeAutoObservable } from "mobx";
import { RemoteData } from "src/common/RemoteData";
import { onError } from "src/common/onError";
import { ifDef } from "src/common/ifDef";
import { loadRefDict } from "src/common/loadRefDict";
import { ZAttributeValue } from "src/types/ZAttribute";
import { ZEntity } from "src/types/ZEntity";
import { FormInstance } from "rc-field-form";
import { EntityFiltersPageStore } from "src/pages/EntityFiltersPage/EntityFiltersPageStore";
import { ZMesChartSettings } from "../ZMesChartSettings";
import {
  copyMeasurementChart,
  createMeasurementChart,
  deleteMeasurementChart,
  loadBaseSizes,
  loadEntitiesAsRef,
  loadMeasurementChart,
  loadMesChartSettings,
  saveMeasurementChart,
  scaleMeasurementChart,
} from "../apiMeasurementChart";
import {
  NewMeasurementChartEntityData,
  ZMCSizeColumn,
  ZMCRow,
  ZMeasurementChart,
  ZMeasurementChartEntity,
} from "../ZMeasurementChart";
import { MCColumn, mkKey } from "./EditMCEntity/MCColumn";
import { buildColumns } from "./EditMCEntity/MChartTable/buildColumns";
import {
  createMcRow,
  ed2mcTable,
  EdMcTable,
} from "./EditMCEntity/MChartTable/EdMcTable";
import {
  NumOption,
  PointDict,
  PointOpt,
} from "./EditMCEntity/MChartTable/PointDict";

type MChartData = {
  settings: ZMesChartSettings;
  mc: ZMeasurementChart;
};

export const mChartEntityStore = makeAutoObservable({
  data: { status: "none" } as RemoteData<MChartData>,
  setData(newData: RemoteData<MChartData>) {
    this.data = newData;
  },
  serviceObjectId: 0,
  modelId: 0,

  async init(serviceObjectId: number, modelId: number) {
    this.serviceObjectId = serviceObjectId;
    this.modelId = modelId;
    this.selected.clear();
    this.hiddenColumns.clear();
    try {
      this.setData({ status: "wait" });
      const settings = await loadMesChartSettings(serviceObjectId);
      await Promise.all([
        this.loadSizeLineOptions(settings),
        this.loadBaseSizeOptions(settings),
        this.loadVersions(settings),
        this.loadPoints(settings),
      ]);
      const mc = await loadMeasurementChart(modelId);
      this.setData({
        status: "ready",
        result: { settings, mc },
      });
    } catch (error) {
      this.setData({ status: "error", error });
    }
  },

  // sizeLineOptions
  sizeLineOptions: [] as NumOption[],
  setSizeLineOptions(list: NumOption[]) {
    this.sizeLineOptions = list;
  },
  async loadSizeLineOptions(settings: ZMesChartSettings) {
    const { sizeLinesObjectId, sizeLinesNameAttrId } = settings;
    if (!sizeLinesObjectId) throw Error(`sizeLinesObjectId is null`);
    if (!sizeLinesNameAttrId) throw Error("sizeLinesNameAttrId is null");
    this.setSizeLineOptions(
      await loadNumOptions(sizeLinesObjectId, sizeLinesNameAttrId, "SizeLines"),
    );
  },
  getMCEntityValue<K extends keyof ZMeasurementChartEntity>(
    name: K,
  ): ZMeasurementChartEntity[K] | undefined {
    return this.data.status === "ready"
      ? this.data.result.mc.measurementChartEntityDto?.[name]
      : undefined;
  },
  get curSizeLineName(): string {
    return (
      ifDef(
        this.getMCEntityValue("sizeLineEntityId"),
        (id) => this.sizeLineOptions.find(({ value }) => value === id)?.label,
      ) ?? "-"
    );
  },

  // baseSize options
  baseSizeOptions: [] as NumOption[],
  setBaseSizeOptions(list: NumOption[]) {
    this.baseSizeOptions = list;
  },
  loadingBaseSize: false,
  setLoadingBaseSize(value: boolean) {
    this.loadingBaseSize = value;
  },
  async loadBaseSizeOptions(settings: ZMesChartSettings) {
    const { sizeNameObjectId, sizeNameNameAttrId } = settings;
    if (!sizeNameObjectId) throw Error("sizeNameObjectId is null");
    if (!sizeNameNameAttrId) throw Error("sizeNameNameAttrId is null");
    this.setBaseSizeOptions(
      await loadNumOptions(sizeNameObjectId, sizeNameNameAttrId, "SizeName"),
    );
  },
  async updateBaseSizeOptions(sizeId: number | null) {
    this.setBaseSizeOptions([]);
    if (sizeId) {
      try {
        this.setLoadingBaseSize(true);
        const list = await loadBaseSizes(this.serviceObjectId, sizeId);
        this.setBaseSizeOptions(list);
      } catch (e) {
        onError(e);
      } finally {
        this.setLoadingBaseSize(false);
      }
    }
  },
  get curBaseSizeName(): string {
    return (
      ifDef(
        this.getMCEntityValue("baseSizeEntityId"),
        (id) => this.baseSizeOptions.find(({ value }) => value === id)?.label,
      ) ?? "-"
    );
  },

  // Versions
  verList: [] as ZAttributeValue[],
  setVerList(list: ZAttributeValue[]) {
    this.verList = list;
  },
  get verOptions(): NumOption[] {
    return this.verList.map(({ id, value }) => ({
      value: id,
      label: value || String(id),
    }));
  },
  get verDict() {
    const dict: Record<number, string> = {};
    this.verOptions.forEach(({ value, label }) => {
      dict[value] = label;
    });
    return dict;
  },
  async loadVersions(settings: ZMesChartSettings) {
    const { versionDictionaryNameAttrId } = settings;
    if (!versionDictionaryNameAttrId)
      throw Error("versionDictionaryNameAttrId is empty");
    const verList = await loadRefDict(versionDictionaryNameAttrId, {
      translate: true,
    });
    if (verList.length === 0)
      throw Error(
        `The version dictionary (#${versionDictionaryNameAttrId}) must contain at least one entry`,
      );
    this.setVerList(verList);
  },

  // Points
  pointOptions: [] as PointOpt[],
  setPointOptions(list: PointOpt[]) {
    this.pointOptions = list;
  },
  get pointDict() {
    const dict: PointDict = {};
    this.pointOptions.forEach((op) => {
      dict[op.value] = op;
    });
    return dict;
  },
  async loadPoints(settings: ZMesChartSettings) {
    const { pointObjectId, pointPointNameAttrId, pointDescriptionAttrId } =
      settings;
    if (!pointObjectId) throw Error("pointObjectId is empty");
    if (!pointPointNameAttrId) throw Error("pointPointNameAttrId is empty");
    if (!pointDescriptionAttrId) throw Error("pointDescriptionAttrId is empty");
    const srcList = await loadEntitiesAsRef(pointObjectId);
    const attrVal = (needAttrId: number, entity: ZEntity): string =>
      entity.attributeValues.find(
        ({ attributeId }) => attributeId === needAttrId,
      )?.values?.[0] ?? "-";
    const dstList = srcList.map(
      (entity) =>
        ({
          value: entity.id,
          label: attrVal(pointPointNameAttrId, entity),
          desc: attrVal(pointDescriptionAttrId, entity),
        }) satisfies PointOpt,
    );
    this.setPointOptions(dstList);
  },

  buzy: false as BuzyType,
  setBuzy(value: BuzyType) {
    this.buzy = value;
  },

  async create(values: NewMeasurementChartEntityData) {
    try {
      this.setBuzy("create");
      const correctedValues = {
        ...values,
        entityId: this.modelId,
        mcServiceId: this.serviceObjectId,
      };
      const mc = await createMeasurementChart(correctedValues);
      this.updateMC(mc);
    } catch (e) {
      onError(e);
    } finally {
      this.setBuzy(false);
    }
  },

  updateMC(mc: ZMeasurementChart) {
    const { data } = this;
    if (data.status !== "ready") throw Error(`Data status: ${data.status}`);
    this.setData({
      status: "ready",
      result: {
        ...data.result,
        mc,
      },
    });
  },

  async reset() {
    try {
      this.setBuzy("reset");
      await deleteMeasurementChart(this.modelId);
    } catch (e) {
      onError(e);
    } finally {
      this.setBuzy(false);
    }
    await this.init(this.serviceObjectId, this.modelId);
  },

  async save(values: EdMcTable) {
    try {
      this.setBuzy("save");
      if (this.data.status === "ready") {
        const { pointDict } = this;
        const tableDto = ed2mcTable(values, this.data.result.mc.tableDto!, {
          pointDict,
        });
        const data: ZMeasurementChart = {
          ...this.data.result.mc,
          tableDto,
        };
        const res = await saveMeasurementChart(this.modelId, data);
        this.updateMC(res);
      }
      return true;
    } catch (e) {
      onError(e);
      return false;
    } finally {
      this.setBuzy(false);
    }
  },

  get canScale(): boolean {
    return this.selected.size !== 0;
  },
  async scale(form: FormInstance) {
    const { selected } = this;
    try {
      try {
        await form.validateFields();
      } catch (e1) {
        throw Error("Invalid form data");
      }
      const values: EdMcTable = form.getFieldsValue();
      this.setBuzy("scale");
      if (this.data.status === "ready") {
        const { pointDict } = this;
        const tableDto = ed2mcTable(values, this.data.result.mc.tableDto!, {
          pointDict,
        });
        tableDto.rows.forEach((row) => {
          // eslint-disable-next-line no-param-reassign
          row.mcPoint.needScale = selected.has(row.mcPoint.id);
        });
        const data: ZMeasurementChart = {
          ...this.data.result.mc,
          tableDto,
        };
        const res = await scaleMeasurementChart(this.modelId, data);
        this.updateMC(res);
      }
      return true;
    } catch (e) {
      onError(e);
      return false;
    } finally {
      this.setBuzy(false);
    }
  },

  get columns(): MCColumn[] {
    return buildColumns(this, this.hiddenColumns);
  },
  hiddenColumns: new Set<string>(),
  toggleColumnVisibility(key: string) {
    const { hiddenColumns } = this;
    if (hiddenColumns.has(key)) {
      hiddenColumns.delete(key);
    } else {
      hiddenColumns.add(key);
    }
  },

  clearHiddenColumns() {
    this.hiddenColumns.clear();
  },
  get sizeColumns(): ZMCSizeColumn[] {
    if (this.data.status === "ready") {
      const { mc } = this.data.result;
      if (mc.columns) return mc.columns;
    }
    return [];
  },
  //
  get sizeVersions() {
    // Size Name => Version name[]
    const dict: Record<string, Set<string>> = {};
    const sizes: ZMCSizeColumn[] =
      (this.data.status === "ready" && this.data.result.mc.columns) || [];
    // Первая версия должна быть всегда
    const verA = this.verOptions[0];
    if (verA) {
      sizes.forEach((sz) => {
        dict[sz.name] = new Set([verA.label]);
      });
    }

    this.rows.forEach(({ mcPoint }) => {
      mcPoint.sizes.forEach((sz) => {
        let ver = dict[sz.name];
        if (!ver) {
          ver = new Set();
          dict[sz.name] = ver;
        }
        sz.pointValues.forEach(({ version }) => {
          ver!.add(version.name);
        });
      });
    });
    return dict;
  },
  get sizeVersionsList() {
    const list: [string, Set<string>][] = Object.entries(this.sizeVersions);
    return list.flatMap(([szName, verSet]) =>
      Array.from(verSet).map((verName) => ({
        key: mkKey(szName, verName),
        szName,
        verName,
      })),
    );
  },
  findNextVersion(sizeName: string): NumOption | undefined {
    const existNames: Set<string> = this.sizeVersions[sizeName] ?? new Set();
    return this.verOptions.find(({ label }) => !existNames.has(label));
  },
  canAddVersion(sizeName: string): boolean {
    if (this.rows.length === 0) return false;
    return !!this.findNextVersion(sizeName);
  },
  addVersion(sizeName: string) {
    const nextVerOpt = this.findNextVersion(sizeName);
    if (nextVerOpt && this.data.status === "ready") {
      this.data.result.mc.tableDto?.rows.forEach(({ mcPoint }) => {
        const sz = mcPoint.sizes.find(({ name }) => name === sizeName);
        if (sz) {
          sz.pointValues.push({
            id: undefined as unknown as number,
            version: {
              id: nextVerOpt.value,
              name: nextVerOpt.label,
            },
            value: null,
          });
        }
      });
    }
  },
  canDeleteVersion(sizeName: string, verName: string): boolean {
    const verList: string[] =
      ifDef(this.sizeVersions[sizeName], (verSet) => Array.from(verSet)) ?? [];
    return verList.length > 1 && verList[verList.length - 1] === verName;
  },
  deleteVersion(sizeName: string, verName: string) {
    const { data } = this;
    if (data.status === "ready") {
      data.result.mc.tableDto?.rows.forEach(({ mcPoint }) => {
        const szItem = mcPoint.sizes.find(({ name }) => name === sizeName);
        if (szItem) {
          szItem.pointValues = szItem.pointValues.filter(
            ({ version }) => version.name !== verName,
          );
        }
      });
    }
  },
  get templateColumns(): string {
    return this.columns.map(({ width }) => width).join(" ");
  },
  get mcData(): ZMeasurementChart | null {
    return this.data.status === "ready" ? this.data.result.mc : null;
  },
  get rows(): ZMCRow[] {
    if (this.data.status === "ready") {
      const rows = this.data.result.mc.tableDto?.rows;
      if (rows) return rows;
    }
    return [];
  },
  addRow(form: FormInstance<EdMcTable>, point?: PointOpt) {
    if (this.data.status === "ready") {
      const { sizeVersions, sizeColumns, verOptions } = this;
      const { mc } = this.data.result;
      const newRow = createMcRow(sizeVersions, sizeColumns, verOptions);
      mc.tableDto = { rows: [...(mc.tableDto?.rows ?? []), newRow] };
      // Обновление полей формы для новой строки
      const sid = String(newRow.mcPoint.id);
      if (point) {
        form.setFieldValue([sid, "pointId"], point.value);
        form.setFieldValue([sid, "description"], point.desc);
      }
      form.setFieldValue([sid, "qc"], false);
      form.setFieldValue([sid, "scale"], 0);
      form.setFieldValue([sid, "tolPlus"], 0);
      form.setFieldValue([sid, "tolMinus"], 0);
      newRow.mcPoint.sizes.forEach(({ name: szName, pointValues }) => {
        pointValues.forEach(({ version }) => {
          form.setFieldValue([sid, "sizes", `${szName}_${version.name}`], 0);
        });
      });
    }
  },
  addRows(form: FormInstance<EdMcTable>, points: PointOpt[]) {
    points.forEach((point) => this.addRow(form, point));
  },
  selected: new Set<number>(),
  toggleSelect(id: number) {
    const { selected } = this;
    if (selected.has(id)) {
      selected.delete(id);
    } else {
      selected.add(id);
    }
  },
  get selectStatus(): "all" | "none" | "partial" {
    const { selected } = this;
    if (selected.size === 0) return "none";
    if (selected.size === this.rows.length) return "all";
    return "partial";
  },
  toggleSelectAll() {
    if (this.selectStatus === "all") {
      this.selected.clear();
    } else {
      this.rows.forEach(({ mcPoint }) => this.selected.add(mcPoint.id));
    }
  },
  deleteSelected() {
    if (this.data.status === "ready") {
      const { tableDto } = this.data.result.mc;
      if (tableDto) {
        tableDto.rows = tableDto.rows.filter(
          ({ mcPoint }) => !this.selected.has(mcPoint.id),
        );
      }
    }
    this.selected.clear();
  },

  templateTable: { status: "none" } as RemoteData<EntityFiltersPageStore>,
  setTemplateTable(newTable: RemoteData<EntityFiltersPageStore>) {
    this.templateTable = newTable;
  },
  async initTemplateTable() {
    try {
      this.setTemplateTable({ status: "wait" });
      const { data } = this;
      if (data.status !== "ready") throw Error("System is not ready");
      const { settings } = data.result;
      const { templateObjectId } = settings;
      if (!templateObjectId) throw Error("Template Object is not selected");
      const result = new EntityFiltersPageStore({
        objectId: templateObjectId,
        selectionSettings: { selectionType: "radio" },
        actions: new Set(),
        onRowClick: (row) => {
          result.tableStore?.safeSelect([row]);
        },
      });
      this.setTemplateTable({ status: "ready", result });
      // Если не вызывать init, то таблица рисуется только при первом появлении. А если переключиться и вернуться, то нет.
      result.init(templateObjectId).catch(onError);
    } catch (error) {
      this.setTemplateTable({ status: "error", error });
    }
  },

  table: { status: "none" } as RemoteData<EntityFiltersPageStore>,
  setTable(newTable: RemoteData<EntityFiltersPageStore>) {
    this.table = newTable;
  },
  async initTable() {
    try {
      this.setTable({ status: "wait" });
      const { data } = this;
      if (data.status !== "ready") throw Error("System is not ready");
      const { settings } = data.result;
      const { objectId } = settings;
      const result = new EntityFiltersPageStore({
        objectId,
        selectionSettings: { selectionType: "radio" },
        actions: new Set(),
        onRowClick: (row) => {
          result.tableStore?.safeSelect([row]);
        },
      });
      this.setTable({ status: "ready", result });
      // Если не вызывать init, то таблица рисуется только при первом появлении. А если переключиться и вернуться, то нет.
      result.init(objectId).catch(onError);
    } catch (error) {
      this.setTable({ status: "error", error });
    }
  },
  async copyFrom(srcModelId: number) {
    const mc = await copyMeasurementChart(srcModelId, this.modelId);
    this.updateMC(mc);
  },
  async copyTo(form: FormInstance): Promise<boolean> {
    try {
      this.setBuzy("copy");
      await form.validateFields();
      const selected =
        (this.table.status === "ready"
          ? this.table.result.tableStore?.selected
          : null) ?? [];
      const dstModel = selected[0];
      if (!dstModel) throw Error("Destination model is not selected");
      await copyMeasurementChart(this.modelId, dstModel.id);
      return true;
    } catch (e) {
      onError(e);
      return false;
    } finally {
      this.setBuzy(false);
    }
  },
});
type BuzyType = "copy" | "create" | "reset" | "save" | "scale" | false;

export type MChartEntityStore = typeof mChartEntityStore;

const loadNumOptions = async (
  objId: number,
  nameAttrId: number,
  objName: string,
): Promise<NumOption[]> => {
  const srcList = await loadEntitiesAsRef(objId);
  const dstList: NumOption[] = [];
  srcList.forEach((entity) => {
    const attr = entity.attributeValues.find(
      ({ attributeId }) => attributeId === nameAttrId,
    );
    const label = attr?.values?.[0];
    if (label) {
      dstList.push({ value: entity.id, label });
    }
  });
  if (dstList.length === 0) throw Error(`${objName} reference is empty`);
  return dstList;
};
