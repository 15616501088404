import { TableStore } from "src/components/tables/TableStore";
import { singleLoader } from "src/components/tables/singleLoader";
import {
  cvtInsensitive,
  strFieldSorter,
} from "src/components/tables/clientSorting";
import { RowSelectionType } from "antd/es/table/interface";
import { PointOpt } from "../MChartTable/PointDict";

export type PointSelectFilters = {
  query?: string;
};

export const createPointSelectStore = (
  pointOptions: PointOpt[],
  selectionType: RowSelectionType,
) =>
  new TableStore<PointOpt, PointSelectFilters>({
    rowKey: "value",
    fnLoad: singleLoader(
      async () => pointOptions,
      ({ query }, { label, desc }) => {
        if (!query) return true;
        const lowQuery = query.toLowerCase();
        return (
          label.toLowerCase().includes(lowQuery) ||
          desc.toLowerCase().includes(lowQuery)
        );
      },
      {
        label: strFieldSorter("label", cvtInsensitive),
        desc: strFieldSorter("desc", cvtInsensitive),
      },
    ),
    initialParams: {
      sort: "label",
      sortOrder: "ascend",
    },
    selectionSettings: {
      keepSelected: true,
      selectionType,
    },
  });
