import * as React from "react";
import { observer } from "mobx-react-lite";
import { ObjectServiceExt } from "src/businessServices/businessServises.types";
import { LoaderBox } from "src/components/LoaderBox";
import { EntityCardDataExt } from "src/pages/EntityCardPage/EntityCardStore";
import { onError } from "src/common/onError";
import { Bom2ControlStore, bom2ControlStore } from "./Bom2ControlStore";

interface PropsBom2Control {
  info: ObjectServiceExt;
  entityData: EntityCardDataExt;
}

export const Bom2Control: React.FC<PropsBom2Control> = observer(
  ({ info, entityData }) => {
    React.useEffect(() => {
      bom2ControlStore.init(info.id, entityData).catch(onError);
    }, [info.id]);
    return (
      <LoaderBox
        remoteData={bom2ControlStore.settings}
        drawReady={() => <Boms2View store={bom2ControlStore} />}
      />
    );
  },
);

interface PropsBoms2View {
  store: Bom2ControlStore;
}
const Boms2View: React.FC<PropsBoms2View> = observer(({ store }) => (
  <div>Boms2View {store.settings.status}</div>
));
