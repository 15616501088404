import * as React from "react";
import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { confirmDelete } from "src/components/ModalDelete";
import styles from "./MCSubtitle.module.less";

interface PropsMCSubtitle {
  verName: string;
  onDelete?(): void;
}

export const MCSubtitle: React.FC<PropsMCSubtitle> = ({
  verName,
  onDelete,
}) => (
  <div className={styles.subtitle}>
    <span className={styles.text}>{verName}</span>
    {!!onDelete && (
      <Button
        className={styles.button}
        icon={<DeleteOutlined />}
        size="small"
        type="text"
        onClick={() =>
          confirmDelete("Are you sure to delete version?", onDelete)
        }
        title="Delete version"
      />
    )}
  </div>
);
MCSubtitle.defaultProps = {
  onDelete: undefined,
};
