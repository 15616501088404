import React, { useEffect, useRef } from "react";
import { Form, FormProps } from "antd";
import styles from "./KeydownSubmitForm.module.less";

type PropsKeydownSubmitForm<TData> = FormProps<TData> & {
  children?: React.ReactNode;
};

export const KeydownSubmitForm = <TData extends {}>({
  children,
  ...props
}: PropsKeydownSubmitForm<TData>) => {
  const { form } = props;
  const formBoxRef = useRef<HTMLDivElement>(null);
  const handleKeyDown = (event: KeyboardEvent) => {
    if ((event.ctrlKey || event.metaKey) && event.key === "Enter") {
      event.stopPropagation();
      const isSubmiting = document.querySelector(
        'button[type="submit"].ant-btn-loading',
      );
      if (!isSubmiting) form?.submit();
    }
  };

  useEffect(() => {
    // При появлении новой формы на странице необходимо ставить ее в фокус, чтобы обрабочик работал уже с ней.
    // Иначе сочетание клавиш вызовет submit не на той форме
    const formBox = formBoxRef.current;
    formBox?.focus();
    formBox?.addEventListener("keydown", (e) => handleKeyDown(e));
    return () => {
      formBox?.removeEventListener("keydown", (e) => handleKeyDown(e));
    };
  }, [form]);

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <div className={styles.formBox} ref={formBoxRef} tabIndex={0}>
      <Form<TData> {...props}>{children}</Form>;
    </div>
  );
};

KeydownSubmitForm.defaultProps = {
  children: undefined,
};
