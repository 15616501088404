import {
  DeleteOutlined,
  DownloadOutlined,
  PictureOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import { Button, UploadFile, Image, Spin, Space } from "antd";
import React from "react";
import { classNames } from "src/common/classNames";
import styles from "./ImageCarouselInner.module.less";

interface PropsImageCarouselInner {
  onDelete: () => void;
  currentImageIndex: number;
  onImageSelect: (index: number) => void;
  fileList: UploadFile[];
  disabled: boolean;
  height?: number;
}

export const ImageCarouselInner: React.FC<PropsImageCarouselInner> = ({
  onDelete,
  height,
  disabled,
  currentImageIndex,
  onImageSelect,
  fileList,
}) => (
  <div className={styles.imageContainer} style={{ height: `${height}px` }}>
    <Image.PreviewGroup
      preview={{
        current: currentImageIndex,
        onChange: (current) => onImageSelect(current),
        // eslint-disable-next-line react/no-unstable-nested-components
        toolbarRender: (
          _,
          {
            transform: { scale },
            actions: { onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
          },
        ) => (
          <Space size={24} className={styles.toolbar}>
            {fileList[currentImageIndex]?.url && (
              <Button
                icon={<DownloadOutlined />}
                href={fileList[currentImageIndex]?.url}
                className={styles.downloadButton}
              />
            )}
            <RotateLeftOutlined onClick={onRotateLeft} />
            <RotateRightOutlined onClick={onRotateRight} />
            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
            {!disabled && <DeleteOutlined onClick={onDelete} />}
          </Space>
        ),
      }}
    >
      {fileList.map((img, index) => (
        <>
          {img?.status === "uploading" && (
            <Spin
              className={classNames([
                styles.image,
                [index === currentImageIndex, styles.active],
              ])}
            />
          )}
          {img?.status === "done" && (img.url || img.thumbUrl) && (
            <Image
              className={classNames([
                styles.image,
                [index === currentImageIndex, styles.active],
              ])}
              src={img?.url || img?.thumbUrl}
            />
          )}
          {img?.status === "error" && (
            <div
              className={classNames([
                styles.imageError,
                [index === currentImageIndex, styles.active],
              ])}
            >
              <PictureOutlined />
            </div>
          )}
        </>
      ))}
    </Image.PreviewGroup>
  </div>
);

ImageCarouselInner.defaultProps = {
  height: undefined,
};
